import React from 'react';

const ImagePreview = ({ images, onRemove }) => (
  <div style={{ display: 'flex', overflowX: 'scroll', marginTop: '10px' }}>
    {images.map((image, index) => (
      <div key={index} style={{ position: 'relative', marginRight: '10px' }}>
        <img
          src={image.url ? image.url : URL.createObjectURL(image.file)}
          alt={`Preview ${index}`}
          style={{ width: '100px', height: '100px', objectFit: 'cover' }}
        />
        {onRemove && (
          <button type="button" onClick={() => onRemove(index)} style={{ position: 'absolute', top: 0, right: 0 }}>
            X
          </button>
        )}
      </div>
    ))}
  </div>
);

export default ImagePreview;
