import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from '../pages/Login';
import Home from '../pages/Home';
import Abertura from '../pages/Abertura/Abertura';
import AdicionarProduto from '../shop/Produtos/AdicionarProduto';
import ProductList from '../shop/Produtos/ProductList';
import Produtos from '../shop/Produtos';
import ProductDetails from '../shop/Produtos/ProductDetails';
import ProductView from '../shop/Produtos/ProductView';
import EditProductTexts from '../shop/Produtos/EditProductTexts';
import EditProductImages from '../shop/Produtos/EditProductImages';
import AdditionalManager from '../shop/AdditionalManager';
import CategoryForm from '../shop/Categorias/CategoryForm';
import CategoryList from '../shop/Categorias/CategoryList';
import Categorias from '../shop/Categorias';
import ClientManager from '../shop/ClientManager';
import CriarPost from '../Posts/CriarPosts';
import Posts from '../Posts';
import PostView from '../Posts/PostView';
import PostEdit from '../Posts/PostEdit';
import Impostos from '../shop/Impostos';
import CriarCupom from '../cupons/CriarCupom';
import Cupons from '../cupons';
import EditarCupom from '../cupons/EditarCupom';
import EntregasAdmin from '../shop/Entregas';
import Empresa from '../shop/Empresa';
import Pedidos from '../shop/Pedidos';
import PedidoView from '../shop/Pedidos/PedidoView';
import StatusPedido from '../shop/Pedidos/Status';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/abertura" element={<Abertura />} />
        <Route path="/adicionar-produto" element={<AdicionarProduto />} />
        <Route path="/lista-produtos" element={<ProductList />} />
        <Route path="/produtos" element={<Produtos />} />
        <Route path="/produto-delhates" element={<ProductDetails />} />
        <Route path="/view-product/:id" element={<ProductView />} />
        <Route path="/edit-product-texts/:id" element={<EditProductTexts />} />
        <Route path="/edit-product-images/:id" element={<EditProductImages />} />
        <Route path="/adicionar-cupom" element={<CriarCupom />} />
        <Route path="/cupons" element={<Cupons />} />
        <Route path="/editar-cupom/:id" element={<EditarCupom />} />

        <Route path="/adicionais" element={<AdditionalManager />} />
        <Route path="/categoria-form" element={<CategoryForm />} />
        <Route path="/categoria-list" element={<CategoryList />} />
        <Route path="/categorias" element={<Categorias />} />
        <Route path="/clientes" element={<ClientManager />} />
        <Route path="/criar-post" element={<CriarPost />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/post/:id" element={<PostView />} />
        <Route path="/post-edit/:id" element={<PostEdit />} />
        <Route path="/impostos" element={<Impostos />} />
        <Route path="/entregas-admin" element={<EntregasAdmin />} />
        <Route path="/empresa-admin" element={<Empresa />} />
        <Route path="/pedidos" element={<Pedidos />} />
        <Route path="/pedido" element={<PedidoView />} />
        <Route path="/status-pedido" element={<StatusPedido />} />

      </Routes>
    </Router>
  );
};

export default AppRoutes;
