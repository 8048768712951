import { useContext } from 'react';
import './Main.css';
import { assets } from '../../assets/assets';
import { Context } from './Context';

const Main = () => {
    const { onSent, recentPrompt, showResult, loading, resultData, setInput, input } = useContext(Context);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && input) {
            onSent();
        }
    };

    return (
        <div className='main'>
            <div className="main-container">
                {!showResult
                    ? <>
                        <div className="greet">
                            <p><span>AppClouds (AI)</span></p>
                        </div>
                        <div className="greetRoxo">
                            <p><spanRoxo>Quer participar dessa nova revolução..?</spanRoxo></p>
                        </div>

                        <div className="cards">
                            <div className="card">
                                <p>Sugerir lugares bonitos para ver em uma próxima viagem de carro</p>
                                <img src={assets.compass_icon} alt="" />
                            </div>
                            <div className="card">
                                <p>Resuma brevemente este conceito: planejamento urbano</p>
                                <img src={assets.bulb_icon} alt="" />
                            </div>
                            <div className="card">
                                <p>Brainstorming de atividades de integração para nosso retiro de trabalho</p>
                                <img src={assets.message_icon} alt="" />
                            </div>
                            <div className="card">
                                <p>Melhorar a legibilidade do código a seguir</p>
                                <img src={assets.code_icon} alt="" />
                            </div>
                        </div>

                    </>
                    : <div className='result'>
                        <div className="result-title">
                            <img src={assets.user_icon} alt="" />
                            <p>{recentPrompt}</p>
                        </div>
                        <div className="result-data">
                            <img src={assets.gemini_icon} alt="" />
                            {loading
                                ? <div className='loader'>
                                    <hr />
                                    <hr />
                                    <hr />
                                </div>
                                : <p dangerouslySetInnerHTML={{ __html: resultData }}></p>
                            }
                        </div>

                    </div>
                }
            </div>
            <div className="main-bottom">
                <div className="search-box">
                    <input
                        onChange={(e) => setInput(e.target.value)}
                        value={input}
                        type="text"
                        placeholder='Digite sua mensagem...'
                        onKeyDown={handleKeyDown}
                    />
                    <div>
                        <img src={assets.gallery_icon} alt="" />
                        <img src={assets.mic_icon} alt="" />
                        {input ? <img onClick={() => onSent()} src={assets.send_icon} alt="" /> : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;
