import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { useParams, useLocation, useNavigate } from 'react-router-dom'; // Adicionei useNavigate
import FileInput from '../FileInput';
import ImagePreview from '../ImagePreview';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const PRODUCT_FOLDER = 'loja-produtos/produtos/';

const EditProductImages = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate(); // Use o hook useNavigate para redirecionamento
  const [imagens, setImagens] = useState([]);
  const [imgBanner, setImgBanner] = useState(null);
  const [existingBanner, setExistingBanner] = useState('');

  useEffect(() => {
    if (location.state && location.state.product) {
      const product = location.state.product;
      setImagens(product.imagens.map(img => ({ url: img })));
      setExistingBanner(product.imgBanner);
    }
  }, [location.state]);

  const handleFileChange = (e, setState) => {
    const files = Array.from(e.target.files);
    if (files.length + imagens.length <= 5) {
      setState(prevImagens => [...prevImagens, ...files.map(file => ({ file }))]);
    } else {
      alert('Você pode enviar no máximo 5 imagens.');
    }
  };

  const handleRemoveImage = (index) => {
    setImagens(prevImagens => prevImagens.filter((_, i) => i !== index));
  };

  const handleBannerChange = (e) => {
    setImgBanner(e.target.files[0]);
  };

  const sanitizeFileName = (str) => {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const sanitizedFileName = sanitizeFileName(location.state.product.nome);
      const sanitizedCategory = sanitizeFileName(location.state.product.categoria);
      const productFolder = `${PRODUCT_FOLDER}${sanitizedCategory}/${id}-${sanitizedFileName}/`;
      const imagesFolder = `${productFolder}images/`;
      const bannerFolder = `${productFolder}banner/`;
      const jsonFileName = `${productFolder}${sanitizedFileName}.json`;

      // Upload images to S3 with public read access
      const uploadedImageNames = [];
      for (let i = 0; i < imagens.length; i++) {
        const image = imagens[i];
        if (image.url) {
          uploadedImageNames.push(image.url.split(`${S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/`)[1]);
        } else {
          const imageFileName = `${imagesFolder}${String(i + 1).padStart(2, '0')}.jpg`;
          const imageUploadParams = {
            Bucket: S3_BUCKET,
            Key: imageFileName,
            Body: image.file,
            ContentType: image.file.type,
            ACL: 'public-read'
          };
          await s3.upload(imageUploadParams).promise();
          uploadedImageNames.push(imageFileName);
        }
      }

      // Upload banner image to S3 with public read access
      let bannerImageName = '';
      if (imgBanner) {
        const bannerImageFileName = `${bannerFolder}banner.jpg`;
        const bannerImageUploadParams = {
          Bucket: S3_BUCKET,
          Key: bannerImageFileName,
          Body: imgBanner,
          ContentType: imgBanner.type,
          ACL: 'public-read'
        };
        await s3.upload(bannerImageUploadParams).promise();
        bannerImageName = bannerImageFileName;
      } else if (existingBanner) {
        bannerImageName = existingBanner.split(`${S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/`)[1];
      }

      const productData = {
        ...location.state.product,
        imagens: uploadedImageNames.map(name => `https://${S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${name}`),
        imgBanner: bannerImageName ? `https://${S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${bannerImageName}` : '',
      };

      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: jsonFileName,
        Body: JSON.stringify(productData),
        ContentType: 'application/json',
        ACL: 'public-read'
      };

      await s3.upload(uploadParams).promise();
      alert('Imagens do produto atualizadas com sucesso!');
      navigate('/manage-products'); // Adicionado para redirecionamento
    } catch (error) {
      console.error('Erro ao atualizar imagens do produto:', error);
      alert('Erro ao atualizar imagens do produto. Verifique as configurações e tente novamente.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FileInput label="Imagens:" onChange={(e) => handleFileChange(e, setImagens)} multiple />
      <ImagePreview images={imagens} onRemove={handleRemoveImage} />
      <FileInput label="Imagem do Banner:" onChange={handleBannerChange} />
      {imgBanner && (
        <div style={{ marginTop: '10px' }}>
          <img
            src={URL.createObjectURL(imgBanner)}
            alt="Preview Banner"
            style={{ width: '200px', height: '100px', objectFit: 'cover' }}
          />
        </div>
      )}
      {existingBanner && !imgBanner && (
        <div style={{ marginTop: '10px' }}>
          <img
            src={existingBanner}
            alt="Preview Banner"
            style={{ width: '200px', height: '100px', objectFit: 'cover' }}
          />
        </div>
      )}
      <button type="submit">Salvar Imagens</button>
    </form>
  );
};

export default EditProductImages;
