import React, { useEffect, useState, useContext } from 'react';
import { S3Client, ListObjectsV2Command, GetObjectCommand } from "@aws-sdk/client-s3";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { FaRegCommentDots, FaRegClock, FaBars } from 'react-icons/fa';
import { marked } from 'marked';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import '../../firebase';
import './Abertura.css';
import Main from './Main';
import Produtos from '../../shop/Produtos';
import Categorias from '../../shop/Categorias';
import Adicionais from '../../shop/AdditionalManager';
import Cupons from '../../cupons';
import Impostos from '../../shop/Impostos';
import { Context } from './Context';
import EntregasAdmin from '../../shop/Entregas';
import Empresa from '../../shop/Empresa';
import Pedidos from '../../shop/Pedidos';
import StatusPedido from '../../shop/Pedidos/Status';

marked.setOptions({
  breaks: true,
  gfm: true,
  headerIds: false,
});

const s3 = new S3Client({
  region: 'sa-east-1',
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

const Abertura = () => {
  const [history, setHistory] = useState([]);
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedChat, setSelectedChat] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState('main'); // Novo estado para controlar a página atual
  const { newChat } = useContext(Context);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const email = user.email.replace(/[@.]/g, '-');
        const folderPath = `assistente/historico/${email}/`;

        try {
          const listCommand = new ListObjectsV2Command({ Bucket: 'ecommerce-app-strapi-expo', Prefix: folderPath });
          const data = await s3.send(listCommand);

          const filePromises = data.Contents.map(async (file) => {
            if (file.Size === 0) {
              return null;  // Ignora arquivos vazios sem emitir aviso
            }

            const getObjectCommand = new GetObjectCommand({ Bucket: 'ecommerce-app-strapi-expo', Key: file.Key });
            const fileData = await s3.send(getObjectCommand);

            const fileBody = await streamToString(fileData.Body);

            if (!fileBody) {
              return null;
            }

            try {
              const chatHistory = JSON.parse(fileBody);

              const timestamp = file.Key.match(/(\d{8}T\d{6})/);
              if (!timestamp) {
                return null;
              }

              const date = new Date(
                parseInt(timestamp[0].slice(0, 4)),
                parseInt(timestamp[0].slice(4, 6)) - 1,
                parseInt(timestamp[0].slice(6, 8)),
                parseInt(timestamp[0].slice(9, 11)),
                parseInt(timestamp[0].slice(11, 13))
              );

              const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')} - ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}h`;

              return {
                title: chatHistory.chatHistory[0].user,
                date: formattedDate,
                fullChat: chatHistory.chatHistory,
              };
            } catch (jsonError) {
              console.error(`Erro ao processar JSON do arquivo ${file.Key}:`, jsonError);
              return null;
            }
          });

          const chatHistoryList = await Promise.all(filePromises);
          const validChatHistoryList = chatHistoryList.filter(item => item !== null);
          setHistory(validChatHistoryList);
          setFilteredHistory(validChatHistoryList);
        } catch (error) {
          console.error('Erro ao carregar histórico de chats:', error);
        } finally {
          setLoading(false);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredHistory(history);
    } else {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      const filtered = history.filter(chat =>
        chat.fullChat.some(
          message =>
            message.user.toLowerCase().includes(lowerCaseSearchTerm) ||
            message.response.toLowerCase().includes(lowerCaseSearchTerm)
        )
      );
      setFilteredHistory(filtered);
    }
  }, [searchTerm, history]);

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
    setCurrentPage('chat');
    setIsSidebarOpen(false);
  };

  const handleNewChat = () => {
    newChat();
    setSelectedChat(null);
    setIsSidebarOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };

  const handleNavigation = (page) => {
    setCurrentPage(page);
    setSelectedChat(null);
    setIsSidebarOpen(false);
  };

  const truncateTitle = (title, wordLimit) => {
    const words = title.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return title;
  };

  const renderMarkdown = (text) => {
    const rawMarkup = marked(text);
    const cleanMarkup = DOMPurify.sanitize(rawMarkup);
    return parse(cleanMarkup);
  };

  const renderContent = () => {
    switch (currentPage) {
      case 'produtos':
        return <Produtos />;
      case 'categorias':
        return <Categorias />;
      case 'adicionais':
        return <Adicionais />;
      case 'cupons':
        return <Cupons />;
      case 'impostos':
        return <Impostos />;
      case 'entregas':
        return <EntregasAdmin />;
      case 'empresa':
        return <Empresa />;
      case 'pedidos':
        return <Pedidos />;
      case 'status':
        return <StatusPedido />;
      case 'chat':
        return selectedChat ? (
          <div>
            <h2>Chat Details</h2>
            {selectedChat.fullChat.map((message, index) => (
              <React.Fragment key={index}>
                <div className="chat-message user-message">
                  <div className="avatar">
                    <img src="https://ecommerce-app-strapi-expo.s3.sa-east-1.amazonaws.com/assistente/icones/user_icon.png" alt="avatar" />
                  </div>
                  <div className="message-content">
                    {renderMarkdown(message.user)}
                  </div>
                </div>
                <div className="chat-message bot-message">
                  <div className="avatar">
                    <img src="https://ecommerce-app-strapi-expo.s3.sa-east-1.amazonaws.com/assistente/icones/assistente_icon.png" alt="avatar" />
                  </div>
                  <div className="message-content">
                    {renderMarkdown(message.response)}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div>Selecione um chat do histórico.</div>
        );
      default:
        return <Main />;
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="chat-history-container">
      <div className="chat-list" style={{ width: isSidebarOpen ? '24%' : '3%' }}>
        <div className="top-bar">
          <FaBars className="menu-icon" onClick={toggleSidebar} />
          {isSidebarOpen && <h4>Aplicativos</h4>}
        </div>

        {isSidebarOpen && (
          <>
            <input
              type="text"
              placeholder="Procurar no histórico..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <ul>
              <div onClick={handleNewChat} className="new-chat">
                Novo Chat
              </div>
              <div className="bottom-item recent-entry" onClick={() => handleNavigation('produtos')}>
                <FaRegCommentDots className="chat-icon" />
                <span>Produtos</span>
              </div>
              <div className="bottom-item recent-entry" onClick={() => handleNavigation('categorias')}>
                <FaRegCommentDots className="chat-icon" />
                <span>Categorias</span>
              </div>
              <div className="bottom-item recent-entry" onClick={() => handleNavigation('adicionais')}>
                <FaRegCommentDots className="chat-icon" />
                <span>Adicionais</span>
              </div>
              <div className="bottom-item recent-entry" onClick={() => handleNavigation('cupons')}>
                <FaRegCommentDots className="chat-icon" />
                <span>Cupons</span>
              </div>
              <div className="bottom-item recent-entry" onClick={() => handleNavigation('impostos')}>
                <FaRegCommentDots className="chat-icon" />
                <span>Impostos</span>
              </div>
              <div className="bottom-item recent-entry" onClick={() => handleNavigation('entregas')}>
                <FaRegCommentDots className="chat-icon" />
                <span>Entregas</span>
              </div>
              <div className="bottom-item recent-entry" onClick={() => handleNavigation('empresa')}>
                <FaRegCommentDots className="chat-icon" />
                <span>Empresa Dados</span>
              </div>
              <div className="bottom-item recent-entry" onClick={() => handleNavigation('pedidos')}>
                <FaRegCommentDots className="chat-icon" />
                <span>Pedidos</span>
              </div>
              <div className="bottom-item recent-entry" onClick={() => handleNavigation('status')}>
                <FaRegCommentDots className="chat-icon" />
                <span>Status dos Pedidos</span>
              </div>
            </ul>
            <h1>Histórico</h1>
            <ul>
              {filteredHistory.slice().reverse().map((chat, index) => (
                <li key={index} onClick={() => handleChatClick(chat)}>
                  <FaRegCommentDots className="chat-icon" />
                  <span>{truncateTitle(chat.title, 5)}</span>
                  <div className="chat-date">
                    <FaRegClock className="clock-icon" />
                    <span>{chat.date}</span>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className="chat-details">
        {renderContent()}
      </div>
    </div>
  );
};

export default Abertura;

const streamToString = async (stream) => {
  const reader = stream.getReader();
  const decoder = new TextDecoder('utf-8');
  let result = '';
  let done = false;
  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    if (value) {
      result += decoder.decode(value, { stream: true });
    }
  }
  result += decoder.decode();
  return result;
};
