import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';

const StatusPedido = () => {
  const [orders, setOrders] = useState([]);
  const statusOptions = ['Em espera', 'Preparando', 'Saiu para entrega', 'Entregue', 'Cancelado'];

  useEffect(() => {
    const fetchAllOrderStatuses = async () => {
      try {
        const s3 = new AWS.S3({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
          region: process.env.REACT_APP_AWS_REGION,
        });

        const params = {
          Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
          Prefix: 'loja-produtos/andamento/',
        };

        const data = await s3.listObjectsV2(params).promise();
        const jsonFiles = data.Contents.filter(item => item.Key.endsWith('.json'));

        const fetchedOrders = await Promise.all(
          jsonFiles.map(async file => {
            try {
              const signedUrl = s3.getSignedUrl('getObject', {
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Key: file.Key,
                Expires: 60 // Expiração de 1 minuto
              });

              const response = await fetch(signedUrl);
              const fileData = await response.json();

              const fileName = file.Key.split('/').pop();
              const dateTimePart = fileName.split('-').slice(2).join(' ').replace('.json', '');
              const dateTimeFormatted = formatDateTime(dateTimePart);

              return {
                ...fileData,
                dateTime: dateTimeFormatted,
                dateTimeRaw: parseDateTime(dateTimePart),
                s3Key: file.Key,
              };
            } catch (error) {
              console.error(`Erro ao buscar o arquivo ${file.Key}:`, error.message);
              return null;
            }
          })
        );

        const validOrders = fetchedOrders.filter(order => order !== null);
        validOrders.sort((a, b) => b.dateTimeRaw - a.dateTimeRaw);
        setOrders(validOrders);
      } catch (error) {
        console.error('Erro ao buscar status dos pedidos:', error.message);
      }
    };

    fetchAllOrderStatuses();
  }, []);

  const formatDateTime = (dateTimePart) => {
    const [day, month, year, hour, minute, second] = dateTimePart.split(' ');
    return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
  };

  const parseDateTime = (dateTimePart) => {
    const [day, month, year, hour, minute, second] = dateTimePart.split(' ');
    return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
  };

  const handleStatusChange = async (order, newStatus) => {
    const updatedOrder = { ...order, status: newStatus };

    try {
      setOrders(prevOrders =>
        prevOrders.map(o => (o.id === order.id ? updatedOrder : o))
      );

      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
      });

      const signedUrl = s3.getSignedUrl('putObject', {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: order.s3Key,
        Expires: 60,
        ContentType: 'application/json',
      });

      await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedOrder, null, 2)
      });

      console.log(`Status do pedido ${order.id} atualizado com sucesso para "${newStatus}".`);
    } catch (error) {
      console.error('Erro ao atualizar o status do pedido:', error.message);
    }
  };

  if (orders.length === 0) {
    return <div>Carregando status dos pedidos...</div>;
  }

  return (
    <div>
      <h1>Status dos Pedidos</h1>
      {orders.map((order, index) => (
        <div key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
          <div><strong>Data e Hora:</strong> {order.dateTime}</div>
          <div><strong>ID do Pedido:</strong> {order.id || 'Não disponível'}</div>
          <div><strong>Nome do Cliente:</strong> {order.cliente?.nome || 'Não disponível'}</div>
          <div><strong>Telefone:</strong> {order.cliente?.telefone || 'Não disponível'}</div>
          <div><strong>Endereço:</strong> {order.cliente?.endereco || 'Não disponível'}</div>
          <div><strong>Total de Produtos:</strong> {order.totalProdutos || 'Não disponível'}</div>
          <div><strong>Total Final:</strong> {order.totalFinal || 'Não disponível'}</div>
          <div>
            <strong>Status:</strong>
            <select
              value={order.status}
              onChange={(e) => handleStatusChange(order, e.target.value)}
            >
              {statusOptions.map(status => (
                <option key={status} value={status}>{status}</option>
              ))}
            </select>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StatusPedido;
