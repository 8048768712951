import React from 'react';

const FileInput = ({ label, onChange, multiple = false, accept = "image/*" }) => (
  <div>
    <label>{label}</label>
    <input type="file" onChange={onChange} multiple={multiple} accept={accept} />
  </div>
);

export default FileInput;
