import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import TextInput from './TextInput';
import NumberInput from './NumberInput';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const TAX_FOLDER = 'loja-produtos/impostos/';

const Impostos = () => {
  const [impostos, setImpostos] = useState([]);
  const [nome, setNome] = useState('');
  const [valor, setValor] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const fetchImpostos = async () => {
      try {
        const listParams = {
          Bucket: S3_BUCKET,
          Prefix: TAX_FOLDER,
        };
        const listedObjects = await s3.listObjectsV2(listParams).promise();
        const impostoPromises = listedObjects.Contents.filter(item => item.Key.endsWith('.json')).map(async (item) => {
          const params = {
            Bucket: S3_BUCKET,
            Key: item.Key,
          };
          const data = await s3.getObject(params).promise();
          try {
            return JSON.parse(data.Body.toString('utf-8'));
          } catch (error) {
            console.error('Erro ao parsear JSON:', error, 'Arquivo:', item.Key);
            return null;
          }
        });

        const impostoData = await Promise.all(impostoPromises);
        setImpostos(impostoData.filter(imposto => imposto !== null));
      } catch (error) {
        console.error('Erro ao buscar impostos:', error);
      }
    };

    fetchImpostos();
  }, []);

  const sanitizeFileName = (str) => {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const sanitizedFileName = sanitizeFileName(nome);
      const id = editMode ? currentId : String(impostos.length + 1).padStart(3, '0');
      const jsonFileName = `${TAX_FOLDER}${id}-${sanitizedFileName}.json`;

      const impostoData = {
        id,
        nome,
        valor: parseFloat(valor.replace(',', '.')),
      };

      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: jsonFileName,
        Body: JSON.stringify(impostoData),
        ContentType: 'application/json',
        ACL: 'public-read'
      };

      await s3.upload(uploadParams).promise();
      alert(editMode ? 'Imposto atualizado com sucesso!' : 'Imposto salvo com sucesso!');

      setImpostos(prevImpostos => {
        if (editMode) {
          return prevImpostos.map(imposto => (imposto.id === id ? impostoData : imposto));
        } else {
          return [...prevImpostos, impostoData];
        }
      });

      setNome('');
      setValor('');
      setEditMode(false);
      setCurrentId(null);
    } catch (error) {
      console.error('Erro ao salvar imposto:', error);
      alert('Erro ao salvar imposto. Verifique as configurações e tente novamente.');
    }
  };

  const handleEdit = (imposto) => {
    setNome(imposto.nome);
    setValor(imposto.valor.toString().replace(',', '.'));
    setEditMode(true);
    setCurrentId(imposto.id);
  };

  const handleDelete = async (id) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const imposto = impostos.find(imposto => imposto.id === id);
      if (!imposto) {
        console.error('Imposto não encontrado:', id);
        alert('Imposto não encontrado.');
        return;
      }

      const sanitizedFileName = imposto.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-z0-9]/g, '-').replace(/-+/g, '-').replace(/^-|-$/g, '');
      const jsonFileName = `${TAX_FOLDER}${id}-${sanitizedFileName}.json`;

      const deleteParams = {
        Bucket: S3_BUCKET,
        Key: jsonFileName,
      };

      await s3.deleteObject(deleteParams).promise();

      setImpostos(impostos.filter(imposto => imposto.id !== id));
      alert('Imposto excluído com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir imposto:', error);
      alert('Erro ao excluir imposto. Verifique as configurações e tente novamente.');
    }
  };

  return (
    <div>
      <h1>Gerenciamento de Impostos</h1>
      <form onSubmit={handleSubmit}>
        <TextInput label="Imposto: " value={nome} onChange={(e) => setNome(e.target.value)} required />
        <NumberInput label="Valor (%):" value={valor} onChange={(e) => setValor(e.target.value)} required />
        <button type="submit">{editMode ? 'Salvar' : 'Adicionar Imposto'}</button>
      </form>
      <ul>
        {impostos.map((imposto, index) => (
          <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <span style={{ flexGrow: 1 }}>{imposto.nome} - {imposto.valor.toFixed(2)}%</span>
            <button onClick={() => handleEdit(imposto)} style={{ marginRight: '10px' }}>Editar</button>
            <button onClick={() => handleDelete(imposto.id)}>Excluir</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Impostos;
