import React, { useState, useEffect } from 'react';
import { FaSave, FaMotorcycle, FaCar, FaTruck } from 'react-icons/fa';
import AWS from 'aws-sdk';
import axios from 'axios';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const REGION = 'sa-east-1';
const JSON_FILE_KEY = 'loja-produtos/admin/entregas-admin.json';

const EntregasAdmin = () => {
  const [entregas, setEntregas] = useState({
    MotoBoy: '',
    AplicativoCar: '',
    AplicativoMoto: '',
    Frete: '',
    Balcao: 'Free',
    Mesa: 'Free',
    Free: 'Free',
    CorreioPac: '',
    CorreioSedex: '',
    CorreioSedex10: ''
  });
  const [enabledOptions, setEnabledOptions] = useState({
    MotoBoy: false,
    AplicativoCar: false,
    AplicativoMoto: false,
    Frete: false,
    Balcao: true,
    Mesa: true,
    Free: true,
    Correio: false
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    loadDeliveryInfo();
  }, []);

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION || REGION,
  });

  const loadDeliveryInfo = async () => {
    try {
      const response = await axios.get(
        `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${JSON_FILE_KEY}`
      );
      const data = response.data;
      setEntregas(data.entregas);
      setEnabledOptions(data.enabledOptions);
    } catch (error) {
      console.error('Error loading delivery info:', error);
      setAlertMessage({ type: 'danger', text: 'Erro ao carregar informações de entrega.' });
    }
  };

  const saveDeliveryInfo = async () => {
    try {
      setLoading(true);
      const data = { entregas, enabledOptions };

      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: JSON_FILE_KEY,
        Body: JSON.stringify(data),
        ContentType: 'application/json',
        ACL: 'public-read',
      };

      await s3.upload(uploadParams).promise();
      setAlertMessage({ type: 'success', text: 'Informações de entrega atualizadas com sucesso!' });
    } catch (error) {
      console.error('Error saving delivery info:', error);
      setAlertMessage({ type: 'danger', text: 'Erro ao salvar informações de entrega.' });
    } finally {
      setLoading(false);
    }
  };

  const validateFields = () => {
    let valid = true;
    let errors = {};
    let anyEnabled = false;

    Object.keys(entregas).forEach((key) => {
      if (enabledOptions[key] || (key.startsWith('Correio') && enabledOptions.Correio)) {
        anyEnabled = true;
        if (
          key !== 'Balcao' &&
          key !== 'Mesa' &&
          key !== 'Free' &&
          (!entregas[key] || isNaN(parseFloat(entregas[key].replace('R$', '').replace(',', '.'))))
        ) {
          errors[key] = 'Valor inválido';
          valid = false;
        }
      }
    });

    if (!anyEnabled) {
      setEnabledOptions((prev) => ({ ...prev, Free: true }));
    }

    setErrors(errors);
    return valid;
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (!validateFields()) {
      setAlertMessage({ type: 'danger', text: 'Por favor, corrija os campos inválidos.' });
      return;
    }
    saveDeliveryInfo();
  };

  const formatCurrency = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    const num = parseFloat(cleanedValue) / 100;
    if (isNaN(num)) return '';
    return num.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const handleCurrencyChange = (text, key) => {
    const formattedValue = formatCurrency(text);
    setEntregas({ ...entregas, [key]: formattedValue });
  };

  return (
    <div style={styles.container}>
      {alertMessage && (
        <div className={`alert alert-${alertMessage.type}`} role="alert">
          {alertMessage.text}
        </div>
      )}
      <h2>Administração de Entregas</h2>
      <form onSubmit={handleSave}>
        {Object.keys(entregas).map((key) => (
          key !== 'CorreioPac' && key !== 'CorreioSedex' && key !== 'CorreioSedex10' && (
            <div key={key} style={styles.inputGroup}>
              <div style={styles.labelContainer}>
                <label>{key}</label>
                <input
                  type="checkbox"
                  checked={enabledOptions[key]}
                  onChange={(e) => setEnabledOptions({ ...enabledOptions, [key]: e.target.checked })}
                />
              </div>
              {key !== 'Balcao' && key !== 'Mesa' && key !== 'Free' && (
                <div style={styles.inputContainer}>
                  <span style={styles.icon}>
                    {key === 'MotoBoy' ? <FaMotorcycle /> : key === 'AplicativoCar' ? <FaCar /> : <FaTruck />}
                  </span>
                  <input
                    type="text"
                    placeholder="Valor"
                    value={entregas[key]}
                    onChange={(e) => handleCurrencyChange(e.target.value, key)}
                    disabled={!enabledOptions[key]}
                    style={styles.input}
                  />
                </div>
              )}
              {errors[key] && <span style={styles.errorText}>{errors[key]}</span>}
            </div>
          )
        ))}
        <div style={styles.inputGroup}>
          <div style={styles.labelContainer}>
            <label>Correio</label>
            <input
              type="checkbox"
              checked={enabledOptions.Correio}
              onChange={(e) => setEnabledOptions({ ...enabledOptions, Correio: e.target.checked })}
            />
          </div>
          {enabledOptions.Correio && (
            <div style={styles.correioContainer}>
              <div style={styles.correioInputContainer}>
                <label>Pac</label>
                <input
                  type="text"
                  placeholder="Valor"
                  value={entregas.CorreioPac}
                  onChange={(e) => handleCurrencyChange(e.target.value, 'CorreioPac')}
                  style={styles.input}
                />
                {errors.CorreioPac && <span style={styles.errorText}>{errors.CorreioPac}</span>}
              </div>
              <div style={styles.correioInputContainer}>
                <label>Sedex</label>
                <input
                  type="text"
                  placeholder="Valor"
                  value={entregas.CorreioSedex}
                  onChange={(e) => handleCurrencyChange(e.target.value, 'CorreioSedex')}
                  style={styles.input}
                />
                {errors.CorreioSedex && <span style={styles.errorText}>{errors.CorreioSedex}</span>}
              </div>
              <div style={styles.correioInputContainer}>
                <label>Sedex 10</label>
                <input
                  type="text"
                  placeholder="Valor"
                  value={entregas.CorreioSedex10}
                  onChange={(e) => handleCurrencyChange(e.target.value, 'CorreioSedex10')}
                  style={styles.input}
                />
                {errors.CorreioSedex10 && <span style={styles.errorText}>{errors.CorreioSedex10}</span>}
              </div>
            </div>
          )}
        </div>
        <button type="submit" style={styles.button} disabled={loading}>
          {loading ? (
            <span>Salvando...</span>
          ) : (
            <>
              <FaSave style={styles.icon} />
              <span>Salvar Alterações</span>
            </>
          )}
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    padding: '16px',
    backgroundColor: '#eee',
    color: '#333',
  },
  inputGroup: {
    marginBottom: '16px',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    padding: '8px',
  },
  correioContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  correioInputContainer: {
    flex: 1,
    marginRight: '8px',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderRadius: '8px',
    border: '1px solid #CCC',
    marginBottom: '8px',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#21C25E',
    borderRadius: '8px',
    padding: '16px',
    color: '#FFF',
    cursor: 'pointer',
  },
  icon: {
    marginRight: '8px',
  },
  errorText: {
    color: 'red',
    fontSize: '12px',
  },
};

export default EntregasAdmin;
