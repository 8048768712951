import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCyDILybVy4ma8SFOQNfVZNvv6UlCTUijg',
  authDomain: 'app-clouds-stores.firebaseapp.com',
  projectId: 'app-clouds-stores',
  storageBucket: 'app-clouds-stores.appspot.com',
  messagingSenderId: '445537388018',
  appId: '1:445537388018:web:b818a194a05a93201fd76b'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, auth, db };
