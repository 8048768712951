import React, { useState } from 'react';
import AWS from 'aws-sdk';
import TextInput from '../shop/TextInput';
import TextArea from '../shop/TextArea';
import DateInput from '../shop/DateInput';
import FileInput from '../shop/FileInput';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const COUPON_FOLDER = 'loja-produtos/cupons/';

const CriarCupom = () => {
  const [codigo, setCodigo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [valorDesconto, setValorDesconto] = useState('');
  const [tipoDesconto, setTipoDesconto] = useState('dinheiro');
  const [dataValidade, setDataValidade] = useState('');
  const [banner, setBanner] = useState(null);
  const [fraseBanner, setFraseBanner] = useState('');

  const handleFileChange = (e) => {
    setBanner(e.target.files[0]);
  };

  const handleRemoveBanner = () => {
    setBanner(null);
  };

  const sanitizeFileName = (str) => {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const sanitizedFileName = sanitizeFileName(codigo);
      const nextId = String(Date.now()).slice(-10); // Gerar um ID único com base no timestamp
      const couponFolder = `${COUPON_FOLDER}${nextId}-${sanitizedFileName}/`;

      // Upload da imagem do banner para a raiz da pasta do cupom
      let bannerImageName = '';
      if (banner) {
        const bannerImageFileName = `${couponFolder}banner.jpg`;
        const bannerImageUploadParams = {
          Bucket: S3_BUCKET,
          Key: bannerImageFileName,
          Body: banner,
          ContentType: banner.type,
          ACL: 'public-read'
        };
        await s3.upload(bannerImageUploadParams).promise();
        bannerImageName = bannerImageFileName;
      }

      // Criar o arquivo JSON do cupom
      const couponData = {
        id: nextId,
        codigo,
        descricao,
        valorDesconto: parseFloat(valorDesconto),
        tipoDesconto,
        dataValidade: new Date(dataValidade).toISOString(),
        fraseBanner,
        banner: bannerImageName ? `https://${S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${bannerImageName}` : '',
      };

      const jsonFileName = `${couponFolder}${nextId}-${sanitizedFileName}.json`;

      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: jsonFileName,
        Body: JSON.stringify(couponData),
        ContentType: 'application/json',
        ACL: 'public-read'
      };

      await s3.upload(uploadParams).promise();
      alert('Cupom adicionado com sucesso!');

      // Limpar o formulário após a submissão
      setCodigo('');
      setDescricao('');
      setValorDesconto('');
      setTipoDesconto('dinheiro');
      setDataValidade('');
      setBanner(null);
      setFraseBanner('');
    } catch (error) {
      console.error('Erro ao adicionar cupom:', error);
      alert('Erro ao adicionar cupom. Verifique as configurações e tente novamente.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextInput label="Código:" value={codigo} onChange={(e) => setCodigo(e.target.value.toUpperCase())} required />
      <TextArea label="Descrição:" value={descricao} onChange={(e) => setDescricao(e.target.value)} required />
      <div>
        <label>Desconto:</label>
        <input type="text" value={valorDesconto} onChange={(e) => setValorDesconto(e.target.value)} required placeholder="0,00" />
        <select value={tipoDesconto} onChange={(e) => setTipoDesconto(e.target.value)}>
          <option value="dinheiro">Dinheiro</option>
          <option value="porcentagem">Porcentagem</option>
        </select>
      </div>
      <DateInput label="Data de Validade:" value={dataValidade} onChange={(e) => setDataValidade(e.target.value)} required />
      <FileInput label="Imagem do Banner:" onChange={handleFileChange} />
      {banner && (
        <div style={{ marginTop: '10px' }}>
          <img
            src={URL.createObjectURL(banner)}
            alt="Preview Banner"
            style={{ width: '200px', height: '100px', objectFit: 'cover' }}
          />
          <button type="button" onClick={handleRemoveBanner}>Remover</button>
        </div>
      )}
      <TextArea label="Frase para o Banner:" value={fraseBanner} onChange={(e) => setFraseBanner(e.target.value)} />
      <button type="submit">Criar Cupom</button>
    </form>
  );
};

export default CriarCupom;
