import React, { useState } from 'react';
import AWS from 'aws-sdk';
import TextInput from '../shop/TextInput';
import TextArea from '../shop/TextArea';
import SelectInput from '../shop/SelectInput';
import DateInput from '../shop/DateInput';
import FileInput from '../shop/FileInput';
import { useNavigate } from 'react-router-dom';
import { OpenAI } from 'openai';
import { FaRobot } from 'react-icons/fa';
import useTypingEffect from '../hooks/useTypingEffect';
import './PostEdit.css';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const POST_FOLDER = 'loja-produtos/posts/';
const IMAGE_FOLDER = 'loja-produtos/posts/images/';

const CriarPost = () => {
  const [titulo, setTitulo] = useState('');
  const [breveDescricao, setBreveDescricao] = useState('');
  const [descricao, setDescricao] = useState('');
  const [tipoPost, setTipoPost] = useState('');
  const [data, setData] = useState('');
  const [imagem, setImagem] = useState(null);
  const [loading, setLoading] = useState({ brief: false, full: false });
  const navigate = useNavigate();

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  const typedBriefDescription = useTypingEffect(breveDescricao, 50);
  const typedDescription = useTypingEffect(descricao, 20);

  const removeAsterisks = (text) => text.replace(/^\*+/, '').trim();

  const generateBriefDescription = async () => {
    setLoading({ ...loading, brief: true });
    setBreveDescricao('');
    try {
      const response = await openai.chat.completions.create({
        model: 'gpt-4o-mini',
        messages: [
          { role: 'user', content: `Gere uma breve descrição para um post de marketing intitulado ${titulo} em 15 palavras ou menos.` },
        ],
        max_tokens: 50,
      });
      const cleanedText = removeAsterisks(response.choices[0].message.content.trim());
      setBreveDescricao(cleanedText);
    } catch (error) {
      console.error('Erro ao gerar breve descrição:', error);
    } finally {
      setLoading({ ...loading, brief: false });
    }
  };

  const generateDescription = async () => {
    setLoading({ ...loading, full: true });
    setDescricao('');
    try {
      const response = await openai.chat.completions.create({
        model: 'gpt-4o-mini',
        messages: [
          { role: 'user', content: `Gere uma descrição completa para um post de marketing intitulado ${titulo}.` },
        ],
        max_tokens: 100,
      });
      const cleanedText = removeAsterisks(response.choices[0].message.content.trim());
      setDescricao(cleanedText);
    } catch (error) {
      console.error('Erro ao gerar descrição completa:', error);
    } finally {
      setLoading({ ...loading, full: false });
    }
  };

  const handleImageChange = (e) => {
    setImagem(e.target.files[0]);
  };

  const sanitizeFileName = (str) => {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const sanitizedTitle = sanitizeFileName(titulo);
      const formattedDate = new Date().toISOString().split('T')[0];
      const fileName = `${POST_FOLDER}${formattedDate}-${sanitizedTitle}.json`;
      const imageFileName = `${IMAGE_FOLDER}${formattedDate}-${sanitizedTitle}.jpg`;

      const postData = {
        id: Date.now().toString(),
        titulo,
        breveDescricao,
        descricao,
        tipoPost,
        data,
        imagem: imageFileName ? `https://${S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${imageFileName}` : '',
      };

      const uploadPostParams = {
        Bucket: S3_BUCKET,
        Key: fileName,
        Body: JSON.stringify(postData),
        ContentType: 'application/json',
        ACL: 'public-read'
      };

      await s3.upload(uploadPostParams).promise();

      if (imagem) {
        const uploadImageParams = {
          Bucket: S3_BUCKET,
          Key: imageFileName,
          Body: imagem,
          ContentType: imagem.type,
          ACL: 'public-read'
        };

        await s3.upload(uploadImageParams).promise();
      }

      alert('Post criado com sucesso!');
      navigate('/posts');
    } catch (error) {
      console.error('Erro ao criar o post:', error);
      alert('Erro ao criar o post. Verifique as configurações e tente novamente.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextInput label="Título:" value={titulo} onChange={(e) => setTitulo(e.target.value)} required />
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <label style={{ marginRight: '5px' }}>Breve Descrição:</label>
        {loading.brief ? (
          <div className="spinner"></div>
        ) : (
          <FaRobot
            onClick={generateBriefDescription}
            style={{ cursor: 'pointer', fontSize: '20px', color: '#007bff' }}
          />
        )}
      </div>
      <TextArea value={typedBriefDescription} onChange={(e) => setBreveDescricao(e.target.value)} required />
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <label style={{ marginRight: '5px' }}>Descrição Completa:</label>
        {loading.full ? (
          <div className="spinner"></div>
        ) : (
          <FaRobot
            onClick={generateDescription}
            style={{ cursor: 'pointer', fontSize: '20px', color: '#007bff' }}
          />
        )}
      </div>
      <TextArea value={typedDescription} onChange={(e) => setDescricao(e.target.value)} required />
      <SelectInput label="Tipo de Post:" value={tipoPost} onChange={(e) => setTipoPost(e.target.value)} options={[
        { value: 'Notícia', label: 'Notícia' },
        { value: 'Evento', label: 'Evento' },
        { value: 'Promoção', label: 'Promoção' },
        { value: 'Desconto', label: 'Desconto' },
        { value: 'Comunicado', label: 'Comunicado' },
        { value: 'Cupom', label: 'Cupom' }
      ]} required />
      <DateInput label="Data:" value={data} onChange={(e) => setData(e.target.value)} required />
      <FileInput label="Imagem do Post:" onChange={handleImageChange} />
      {imagem && (
        <div style={{ marginTop: '10px' }}>
          <img
            src={URL.createObjectURL(imagem)}
            alt="Preview Imagem"
            style={{ width: '200px', height: '100px', objectFit: 'cover' }}
          />
        </div>
      )}
      <button type="submit">Criar Post</button>
    </form>
  );
};

export default CriarPost;
