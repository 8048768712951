import React from 'react';
import { useLocation } from 'react-router-dom';
import './PostView.css';

const PostView = () => {
  const location = useLocation();
  const { post } = location.state || {};

  if (!post) {
    return <div>Post não encontrado.</div>;
  }

  return (
    <div className="post-view">
      <img src={post.imagem} alt={post.titulo} className="post-view-image" />
      <h1>{post.titulo}</h1>
      <p><strong>Breve Descrição:</strong> {post.breveDescricao}</p>
      <p><strong>Descrição Completa:</strong></p>
      <p>{post.descricao}</p>
      <p><strong>Tipo de Post:</strong> {post.tipoPost}</p>
      <p><strong>Data:</strong> {post.data}</p>
    </div>
  );
};

export default PostView;
