import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AWS from 'aws-sdk';
import TextInput from '../shop/TextInput';
import TextArea from '../shop/TextArea';
import DateInput from '../shop/DateInput';
import FileInput from '../shop/FileInput';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const COUPON_FOLDER = 'loja-produtos/cupons/';

const EditarCupom = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [codigo, setCodigo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [valorDesconto, setValorDesconto] = useState('');
  const [tipoDesconto, setTipoDesconto] = useState('dinheiro');
  const [dataValidade, setDataValidade] = useState('');
  const [banner, setBanner] = useState(null);
  const [fraseBanner, setFraseBanner] = useState('');
  const [existingBannerUrl, setExistingBannerUrl] = useState('');

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const fetchCupom = async () => {
      try {
        // Tentando construir o caminho completo com ID e codigo depois de obter o arquivo
        const listParams = {
          Bucket: S3_BUCKET,
          Prefix: `${COUPON_FOLDER}${id}-`,
        };
        const listedObjects = await s3.listObjectsV2(listParams).promise();

        if (listedObjects.Contents.length > 0) {
          const firstObjectKey = listedObjects.Contents[0].Key; // Pega o primeiro arquivo correspondente
          const params = {
            Bucket: S3_BUCKET,
            Key: firstObjectKey,
          };
          const data = await s3.getObject(params).promise();
          const cupom = JSON.parse(data.Body.toString('utf-8'));

          setCodigo(cupom.codigo);
          setDescricao(cupom.descricao);
          setValorDesconto(cupom.valorDesconto);
          setTipoDesconto(cupom.tipoDesconto);
          setDataValidade(new Date(cupom.dataValidade).toISOString().split('T')[0]);
          setFraseBanner(cupom.fraseBanner);
          setExistingBannerUrl(cupom.banner);
        } else {
          console.error('Cupom não encontrado.');
        }
      } catch (error) {
        console.error('Erro ao buscar o cupom:', error);
      }
    };

    fetchCupom();
  }, [id]);

  const handleFileChange = (e) => {
    setBanner(e.target.files[0]);
  };

  const handleRemoveBanner = () => {
    setBanner(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const sanitizedFileName = `${id}-${codigo.toLowerCase().replace(/[^a-z0-9]/g, '-')}`;
      const bannerFolder = `${COUPON_FOLDER}${sanitizedFileName}/banner/`;

      let bannerImageName = existingBannerUrl;
      if (banner) {
        const bannerImageFileName = `${bannerFolder}banner.jpg`;
        const bannerImageUploadParams = {
          Bucket: S3_BUCKET,
          Key: bannerImageFileName,
          Body: banner,
          ContentType: banner.type,
          ACL: 'public-read',
        };
        await s3.upload(bannerImageUploadParams).promise();
        bannerImageName = `https://${S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${bannerImageFileName}`;
      }

      const updatedCupom = {
        id,
        codigo,
        descricao,
        valorDesconto: parseFloat(valorDesconto),
        tipoDesconto,
        dataValidade: new Date(dataValidade).toISOString(),
        fraseBanner,
        banner: bannerImageName,
      };

      const jsonFileName = `${COUPON_FOLDER}${sanitizedFileName}.json`;

      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: jsonFileName,
        Body: JSON.stringify(updatedCupom),
        ContentType: 'application/json',
        ACL: 'public-read',
      };

      await s3.upload(uploadParams).promise();
      alert('Cupom atualizado com sucesso!');

      navigate('/cupons');
    } catch (error) {
      console.error('Erro ao atualizar o cupom:', error);
      alert('Erro ao atualizar o cupom. Verifique as configurações e tente novamente.');
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
      <h1>Editar Cupom</h1>
      <TextInput label="Código:" value={codigo} onChange={(e) => setCodigo(e.target.value.toUpperCase())} required />
      <TextArea label="Descrição:" value={descricao} onChange={(e) => setDescricao(e.target.value)} required />
      <div>
        <label>Desconto:</label>
        <input type="text" value={valorDesconto} onChange={(e) => setValorDesconto(e.target.value)} required placeholder="0.00" />
        <select value={tipoDesconto} onChange={(e) => setTipoDesconto(e.target.value)}>
          <option value="dinheiro">Dinheiro</option>
          <option value="porcentagem">Porcentagem</option>
        </select>
      </div>
      <DateInput label="Data de Validade:" value={dataValidade} onChange={(e) => setDataValidade(e.target.value)} required />
      <FileInput label="Imagem do Banner:" onChange={handleFileChange} />
      {existingBannerUrl && !banner && (
        <div style={{ marginTop: '10px' }}>
          <img
            src={existingBannerUrl}
            alt="Preview Banner"
            style={{ width: '200px', height: '100px', objectFit: 'cover' }}
          />
          <button type="button" onClick={handleRemoveBanner}>Remover</button>
        </div>
      )}
      {banner && (
        <div style={{ marginTop: '10px' }}>
          <img
            src={URL.createObjectURL(banner)}
            alt="Preview Banner"
            style={{ width: '200px', height: '100px', objectFit: 'cover' }}
          />
          <button type="button" onClick={handleRemoveBanner}>Remover</button>
        </div>
      )}
      <TextArea label="Frase para o Banner:" value={fraseBanner} onChange={(e) => setFraseBanner(e.target.value)} />
      <button type="submit">Salvar Alterações</button>
    </form>
  );
};

export default EditarCupom;
