import React from 'react';

const SelectInput = ({ label, value, onChange, options, required = false }) => (
  <div>
    <label>{label}</label>
    <select value={value} onChange={onChange} required={required}>
      <option value="">Selecione uma opção</option>
      {options.map((option, index) => (
        <option key={index} value={option.value}>{option.label}</option>
      ))}
    </select>
  </div>
);

export default SelectInput;
