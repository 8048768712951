import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import TextInput from '../TextInput';
import TextArea from '../TextArea';
import NumberInput from '../NumberInput';
import DateInput from '../DateInput';
import SelectInput from '../SelectInput';
import TagInput from '../TagInput';
import { OpenAI } from 'openai';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const PRODUCT_FOLDER = 'loja-produtos/produtos/';
const CATEGORY_FOLDER = 'loja-produtos/categorias/';
const ADDITIONAL_FOLDER = 'loja-produtos/adicionais/';

const EditProductTexts = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate(); // Adicionado para redirecionamento
  const [nome, setNome] = useState('');
  const [breveDescricao, setBreveDescricao] = useState('');
  const [descricao, setDescricao] = useState('');
  const [preco, setPreco] = useState('');
  const [precoPromocional, setPrecoPromocional] = useState('');
  const [dataValidadePromocao, setDataValidadePromocao] = useState('');
  const [categoria, setCategoria] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [adicionais, setAdicionais] = useState([]);
  const [selectedAdicionais, setSelectedAdicionais] = useState([]);

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  useEffect(() => {
    if (location.state && location.state.product) {
      const product = location.state.product;
      setNome(product.nome);
      setBreveDescricao(product.breveDescricao);
      setDescricao(product.descricao);
      setPreco(product.preco);
      setPrecoPromocional(product.precoPromocional);
      setDataValidadePromocao(product.dataValidadePromocao ? new Date(product.dataValidadePromocao).toISOString().split('T')[0] : '');
      setCategoria(product.categoria);
      setSelectedAdicionais(product.adicionais || []);
    }
  }, [location.state]);

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const fetchCategoriesAndAdicionais = async () => {
      try {
        const listParams = {
          Bucket: S3_BUCKET,
          Prefix: CATEGORY_FOLDER,
        };
        const listedObjects = await s3.listObjectsV2(listParams).promise();
        const categoryPromises = listedObjects.Contents.filter(item => item.Key.endsWith('.json')).map(async (item) => {
          const params = {
            Bucket: S3_BUCKET,
            Key: item.Key,
          };
          const data = await s3.getObject(params).promise();
          try {
            return JSON.parse(data.Body.toString('utf-8'));
          } catch (error) {
            console.error('Erro ao parsear JSON:', error, 'Arquivo:', item.Key);
            return null;
          }
        });

        const categoryData = await Promise.all(categoryPromises);
        setCategorias(categoryData.filter(cat => cat !== null));

        const listAdditionalParams = {
          Bucket: S3_BUCKET,
          Prefix: ADDITIONAL_FOLDER,
        };
        const listedAdditionalObjects = await s3.listObjectsV2(listAdditionalParams).promise();
        const additionalPromises = listedAdditionalObjects.Contents.filter(item => item.Key.endsWith('.json')).map(async (item) => {
          const params = {
            Bucket: S3_BUCKET,
            Key: item.Key,
          };
          const data = await s3.getObject(params).promise();
          try {
            return JSON.parse(data.Body.toString('utf-8'));
          } catch (error) {
            console.error('Erro ao parsear JSON:', error, 'Arquivo:', item.Key);
            return null;
          }
        });

        const additionalData = await Promise.all(additionalPromises);
        setAdicionais(additionalData.filter(add => add !== null));
      } catch (error) {
        console.error('Erro ao buscar categorias e adicionais:', error);
      }
    };

    fetchCategoriesAndAdicionais();
  }, []);

  const generateDescription = async () => {
    try {
      const response = await openai.chat.completions.create({
        model: 'gpt-4o-mini',
        messages: [
          { role: 'system', content: 'You are a helpful assistant.' },
          { role: 'user', content: `Generate a product description for a product named ${nome}` },
        ],
        max_tokens: 100,
      });
      setDescricao(response.choices[0].message.content.trim());
    } catch (error) {
      console.error('Error generating description:', error);
    }
  };

  const generateBriefDescription = async () => {
    try {
      const response = await openai.chat.completions.create({
        model: 'gpt-4o-mini',
        messages: [
          { role: 'system', content: 'You are a helpful assistant.' },
          { role: 'user', content: `Generate a brief product description for a product named ${nome} in 15 words or less` },
        ],
        max_tokens: 50,
      });
      setBreveDescricao(response.choices[0].message.content.trim());
    } catch (error) {
      console.error('Error generating brief description:', error);
    }
  };

  const sanitizeFileName = (str) => {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const sanitizedFileName = sanitizeFileName(nome);
      const sanitizedCategory = sanitizeFileName(categoria);
      const productFolder = `${PRODUCT_FOLDER}${sanitizedCategory}/${id}-${sanitizedFileName}/`;
      const jsonFileName = `${productFolder}${sanitizedFileName}.json`;

      const productData = {
        id,
        nome,
        breveDescricao,
        descricao,
        preco: parseFloat(preco),
        precoPromocional: precoPromocional ? parseFloat(precoPromocional) : null,
        dataValidadePromocao: dataValidadePromocao ? new Date(dataValidadePromocao).toISOString() : null,
        categoria,
        imagens: location.state.product.imagens,
        video: location.state.product.video,
        imgBanner: location.state.product.imgBanner,
        textoBanner: location.state.product.textoBanner,
        adicionais: selectedAdicionais,
      };

      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: jsonFileName,
        Body: JSON.stringify(productData),
        ContentType: 'application/json',
        ACL: 'public-read'
      };

      await s3.upload(uploadParams).promise();
      alert('Textos do produto atualizados com sucesso!');
      navigate('/produtos'); // Adicionado para redirecionamento
    } catch (error) {
      console.error('Erro ao atualizar textos do produto:', error);
      alert('Erro ao atualizar textos do produto. Verifique as configurações e tente novamente.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextInput label="Nome:" value={nome} onChange={(e) => setNome(e.target.value)} required />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextArea label="Breve Descrição:" value={breveDescricao} onChange={(e) => setBreveDescricao(e.target.value)} required />
        <button type="button" onClick={generateBriefDescription} style={{ marginLeft: '10px' }}>Gerar Breve Descrição</button>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextArea label="Descrição:" value={descricao} onChange={(e) => setDescricao(e.target.value)} required />
        <button type="button" onClick={generateDescription} style={{ marginLeft: '10px' }}>Gerar Descrição</button>
      </div>
      <NumberInput label="Preço:" value={preco} onChange={(e) => setPreco(e.target.value)} required />
      <NumberInput label="Preço Promocional:" value={precoPromocional} onChange={(e) => setPrecoPromocional(e.target.value)} />
      <DateInput label="Data de Validade da Promoção:" value={dataValidadePromocao} onChange={(e) => setDataValidadePromocao(e.target.value)} />
      <SelectInput label="Categoria:" value={categoria} onChange={(e) => setCategoria(e.target.value)} options={categorias.map(cat => ({ value: cat.nome, label: cat.nome }))} required />
      <TagInput label="Adicionais:" options={adicionais} value={selectedAdicionais} onChange={setSelectedAdicionais} />
      <button type="submit">Salvar Textos</button>
    </form>
  );
};

export default EditProductTexts;