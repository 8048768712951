import AWS from 'aws-sdk';

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;

export const deleteFileFromS3 = async (key) => {
  try {
    console.log(`Deleting file from S3 with key ${key}`);
    const params = {
      Bucket: bucketName,
      Key: key,
    };

    await s3.deleteObject(params).promise();
    console.log(`File deleted successfully at ${params.Key}`);
  } catch (error) {
    console.error('Delete error:', error);
    throw error;
  }
};

export const listFilesInS3 = async (prefix) => {
  try {
    const params = {
      Bucket: bucketName,
      Prefix: prefix,
    };
    const result = await s3.listObjectsV2(params).promise();
    return result.Contents;
  } catch (error) {
    console.error('Erro ao listar arquivos no S3:', error);
    throw error;
  }
};

export const cleanString = (str) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remover acentos
    .replace(/[^a-zA-Z0-9]/g, '-') // Substituir caracteres especiais por hífen
    .toLowerCase()
    .replace(/-+/g, '-'); // Substituir múltiplos hífens por um único hífen
};

export const uploadOrder = async (order) => {
  const fileName = `${cleanString(order.customerName)}-${Date.now()}`;
  const jsonKey = `loja-produtos/recibos/${fileName}.json`;

  try {
    const orderBlob = new Blob([JSON.stringify(order)], { type: 'application/json' });

    const params = {
      Bucket: bucketName,
      Key: jsonKey,
      Body: orderBlob,
      ContentType: 'application/json',
      ACL: 'public-read',
    };

    await s3.upload(params).promise();
    alert('Ordem salva com sucesso!');
  } catch (error) {
    console.error('Erro ao salvar a ordem:', error);
    alert('Erro ao salvar a ordem.');
    throw error;
  }
};

export const listOrdersFromS3 = async () => {
  try {
    const data = await listFilesInS3('loja-produtos/recibos/');
    const orderFiles = data.filter(item => item.Key.endsWith('.json'));

    const orders = await Promise.all(
      orderFiles.map(async (file) => {
        const params = {
          Bucket: bucketName,
          Key: file.Key,
        };
        const fileData = await s3.getObject(params).promise();
        const order = JSON.parse(fileData.Body.toString('utf-8'));
        order.id = file.Key;
        return order;
      })
    );

    return orders;
  } catch (error) {
    console.error('Erro ao listar ordens:', error);
    alert('Erro ao listar ordens.');
    throw error;
  }
};

export const getOrderById = async (orderId) => {
  try {
    const params = {
      Bucket: bucketName,
      Key: orderId,
    };

    const fileData = await s3.getObject(params).promise();
    const order = JSON.parse(fileData.Body.toString('utf-8'));
    return order;
  } catch (error) {
    console.error('Erro ao buscar ordem:', error);
    alert('Erro ao buscar ordem.');
    throw error;
  }
};

export const updateOrder = async (order) => {
  const fileName = order.id.split('/').pop().replace('.json', '');
  const jsonKey = `loja-produtos/recibos/${fileName}.json`;

  try {
    const orderBlob = new Blob([JSON.stringify(order)], { type: 'application/json' });

    const params = {
      Bucket: bucketName,
      Key: jsonKey,
      Body: orderBlob,
      ContentType: 'application/json',
      ACL: 'public-read',
    };

    await s3.upload(params).promise();
    alert('Ordem atualizada com sucesso!');
  } catch (error) {
    console.error('Erro ao atualizar a ordem:', error);
    alert('Erro ao atualizar a ordem.');
    throw error;
  }
};

export const deleteOrder = async (orderId) => {
  try {
    await deleteFileFromS3(orderId);
    alert('Ordem excluída com sucesso!');
  } catch (error) {
    console.error('Erro ao excluir a ordem:', error);
    alert('Erro ao excluir a ordem.');
    throw error;
  }
};

export const updateOrderStatus = async (receiptFileName, newStatus) => {
  try {
    const s3Key = `loja-produtos/andamento/${receiptFileName}`;

    const paramsGet = {
      Bucket: bucketName,
      Key: s3Key,
    };

    const fileData = await s3.getObject(paramsGet).promise();
    const andamentoData = JSON.parse(fileData.Body.toString('utf-8'));
    andamentoData.status = newStatus;

    const updatedBlob = JSON.stringify(andamentoData);

    const paramsPut = {
      Bucket: bucketName,
      Key: s3Key,
      Body: updatedBlob,
      ContentType: 'application/json',
      ACL: 'public-read', // Adiciona ACL pública
    };

    await s3.putObject(paramsPut).promise();

    alert('Status da ordem atualizado com sucesso!');
  } catch (error) {
    console.error('Erro ao atualizar o status do pedido:', error);
    alert('Erro ao atualizar o status do pedido.');
    throw error;
  }
};


export const fetchStatusFromS3 = async (s3Key) => {
  try {
    const params = {
      Bucket: bucketName,
      Key: s3Key,
    };

    const fileData = await s3.getObject(params).promise();
    const order = JSON.parse(fileData.Body.toString('utf-8'));

    return order.status || 'Status não disponível';
  } catch (error) {
    console.error('Erro ao buscar status do S3:', error);

    if (error.code === 'NoSuchKey') {
      console.warn(`Chave ${s3Key} não encontrada no S3.`);
      return 'Status não disponível';
    }

    alert('Erro ao buscar status do pedido.');
    throw error;
  }
};

export const uploadFileToS3 = async (fileData, key) => {
  try {
    console.log(`Uploading file to S3 with key: ${key}`);

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: key,
      Body: fileData,
      ContentType: 'application/json',
    };

    const result = await s3.putObject(params).promise();
    console.log(`File uploaded successfully at ${key}`, result);
  } catch (error) {
    console.error('Error uploading file to S3:', error);
    throw error;
  }
};
