import React, { useState, useEffect } from 'react';
import { FaSave, FaUser, FaFileAlt, FaMapMarkedAlt, FaPhone, FaWhatsapp, FaTextHeight } from 'react-icons/fa';
import AWS from 'aws-sdk';
import axios from 'axios';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const REGION = 'sa-east-1';
const JSON_FILE_KEY = 'loja-produtos/admin/data-store.json';
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Empresa = () => {
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [cnpjCpf, setCnpjCpf] = useState('');
  const [endereco, setEndereco] = useState('');
  const [telefone, setTelefone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [focusedField, setFocusedField] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${JSON_FILE_KEY}`);
        const data = response.data;
        setNome(data.nome || '');
        setDescricao(data.descricao || '');
        setCnpjCpf(data.cnpjCpf || '');
        setEndereco(data.endereco || '');
        setTelefone(data.telefone || '');
        setWhatsapp(data.whatsapp || '');
        setFacebook(data.facebook || '');
        setInstagram(data.instagram || '');
        setTwitter(data.twitter || '');
      } catch (error) {
        console.error('Erro ao carregar dados', error);
        alert('Erro ao carregar dados do servidor.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleAddressChange = async (text) => {
    setEndereco(text);
    if (text.length >= 2) {
      const apiUrl = `https://maps.googleapis.com/maps/api/place/autocomplete/json?key=${GOOGLE_MAPS_API_KEY}&input=${text}&components=country:br&language=pt-BR`;

      try {
        const result = await axios.get(apiUrl);
        const json = result.data;
        setSuggestions(json.predictions);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const selectSuggestion = (suggestion) => {
    setEndereco(suggestion.description);
    setSuggestions([]);
  };

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION || REGION,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      nome: nome.trim(),
      descricao: descricao.trim(),
      cnpjCpf: cnpjCpf.trim(),
      endereco: endereco.trim(),
      telefone: telefone.trim(),
      whatsapp: whatsapp.trim(),
      facebook: facebook.trim(),
      instagram: instagram.trim(),
      twitter: twitter.trim(),
    };

    try {
      const jsonBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });

      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: JSON_FILE_KEY,
        Body: jsonBlob,
        ContentType: 'application/json',
        ACL: 'public-read',
      };

      await s3.upload(uploadParams).promise();
      alert('Dados salvos e enviados para o S3 com sucesso!');
    } catch (error) {
      console.error('Erro ao salvar ou enviar os dados', error);
      alert('Erro ao salvar ou enviar os dados.');
    }
  };

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <p>Carregando...</p>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <h2>Dados do Aplicativo</h2>
      <p>Configurações da Loja e do Aplicativo</p>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.inputContainer}>
          <FaUser style={styles.icon} />
          <input
            type="text"
            placeholder="Nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            style={styles.input}
          />
        </div>

        <div style={styles.inputContainer}>
          <FaTextHeight style={styles.icon} />
          <textarea
            placeholder="Descrição"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            style={styles.textarea}
            rows="2"
          />
        </div>

        <div style={styles.inputContainer}>
          <FaFileAlt style={styles.icon} />
          <input
            type="text"
            placeholder="CNPJ/CPF"
            value={cnpjCpf}
            onChange={(e) => setCnpjCpf(e.target.value)}
            style={styles.input}
          />
        </div>

        <div style={styles.inputGroup}>
          <div style={{ ...styles.inputContainer, ...(focusedField === 'endereco' ? styles.focusedInputContainer : {}) }}>
            <FaMapMarkedAlt style={styles.icon} />
            <input
              type="text"
              placeholder="Endereço"
              value={endereco}
              onChange={(e) => handleAddressChange(e.target.value)}
              onFocus={() => setFocusedField('endereco')}
              onBlur={() => setFocusedField(null)}
              style={styles.input}
            />
          </div>
          {errors.endereco && <p style={styles.errorText}>{errors.endereco}</p>}
          {suggestions.length > 0 && (
            <ul style={styles.suggestionsContainer}>
              {suggestions.map((item) => (
                <li key={item.place_id} onClick={() => selectSuggestion(item)} style={styles.suggestion}>
                  {item.description}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div style={styles.inputContainer}>
          <FaPhone style={styles.icon} />
          <input
            type="text"
            placeholder="Telefone"
            value={telefone}
            onChange={(e) => setTelefone(e.target.value)}
            style={styles.input}
          />
        </div>

        <div style={styles.inputContainer}>
          <FaWhatsapp style={styles.icon} />
          <input
            type="text"
            placeholder="WhatsApp"
            value={whatsapp}
            onChange={(e) => setWhatsapp(e.target.value)}
            style={styles.input}
          />
        </div>

        <button type="submit" style={styles.button}>
          <FaSave style={styles.iconButton} />
          Salvar
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    padding: '16px',
    backgroundColor: '#F5F5F5',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    position: 'relative',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #CCC',
  },
  textarea: {
    flex: 1,
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #CCC',
  },
  icon: {
    marginRight: '8px',
  },
  iconButton: {
    marginRight: '8px',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#21C25E',
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  suggestionsContainer: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#FFF',
    border: '1px solid #CCC',
    borderRadius: '4px',
    zIndex: 10,
  },
  suggestion: {
    padding: '8px',
    cursor: 'pointer',
  },
  errorText: {
    color: 'red',
    fontSize: '12px',
    marginTop: '4px',
  },
  loadingContainer: {
    padding: '20px',
    textAlign: 'center',
  },
};

export default Empresa;
