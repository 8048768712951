import { createContext, useState, useEffect, useCallback } from "react";
import runChat from "../../components/Gemini/Gemini";
import { S3Client, PutObjectCommand, HeadObjectCommand } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import '../../firebase';

export const Context = createContext();

const S3_BUCKET = 'ecommerce-app-strapi-expo';

const s3 = new S3Client({
    region: 'sa-east-1',
    credentials: {
        accessKeyId: 'AKIARVQOSBJE5DYEX6GW',
        secretAccessKey: '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',
    }
});

const createUserFolder = async (folderName) => {
    const params = {
        Bucket: S3_BUCKET,
        Key: `${folderName}/`,
        ACL: 'public-read'
    };

    try {

        const headParams = {
            Bucket: S3_BUCKET,
            Key: `${folderName}/`
        };
        await s3.send(new HeadObjectCommand(headParams));

    } catch (err) {
        if (err.name === 'NotFound') {
            try {
                const command = new PutObjectCommand(params);
                await s3.send(command);

            } catch (createErr) {
                console.error('Error creating folder:', createErr);
            }
        } else {
            console.error('Error checking folder existence:', err);
        }
    }
};

const ContextProvider = (props) => {
    const [input, setInput] = useState("");
    const [recentPrompt, setRecentPrompt] = useState("");
    const [prevPrompts, setPrevPrompts] = useState([]);
    const [showResult, setShowResult] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resultData, setResultData] = useState("");
    const [sidebarWidth, setSidebarWidth] = useState(260);
    const [chatHistory, setChatHistory] = useState([]);
    const [timeoutId, setTimeoutId] = useState(null);
    const [chatId, setChatId] = useState(uuidv4());
    const [userEmail, setUserEmail] = useState(null);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const formattedEmail = user.email.replace(/[@.]/g, '-');
                const userFolder = `assistente/historico/${formattedEmail}`;
                setUserEmail(user.email);
                await createUserFolder(userFolder);

            } else {
                setUserEmail(null);
                console.log('User not logged in');
            }
        });

        return () => unsubscribe();
    }, []);

    const saveChatHistory = useCallback(async () => {
        if (chatHistory.length === 0) {

            return;
        }

        if (!userEmail) {
            console.log('User not authenticated');
            return;
        }

        const formattedEmail = userEmail.replace(/[@.]/g, '-');
        const userFolder = `assistente/historico/${formattedEmail}`;
        const date = new Date();
        const formattedDate = date.toISOString().replace(/[:-]/g, '').split('.')[0];
        const fileName = `${formattedDate}.json`;
        const fileContent = JSON.stringify({ email: userEmail, chatHistory });
        const params = {
            Bucket: S3_BUCKET,
            Key: `${userFolder}/${fileName}`,
            Body: fileContent,
            ContentType: "application/json",
            ACL: 'public-read'
        };


        try {
            const command = new PutObjectCommand(params);
            const data = await s3.send(command);
            console.log('Successfully uploaded data:', data);
        } catch (err) {
            console.error('Error uploading data:', err);
        }
    }, [chatHistory, userEmail]);

    const resetInactivityTimer = () => {
        if (timeoutId) clearTimeout(timeoutId);
        const newTimeoutId = setTimeout(saveChatHistory, 99000);
        setTimeoutId(newTimeoutId);

    };

    useEffect(() => {
        window.addEventListener('beforeunload', saveChatHistory);
        return () => {
            window.removeEventListener('beforeunload', saveChatHistory);
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [saveChatHistory, timeoutId]);

    useEffect(() => {
        resetInactivityTimer();
    }, [input, chatHistory]);

    const delayPara = (index, nextWord) => {
        setTimeout(() => {
            setResultData(prev => prev + nextWord);
        }, 75 * index);
    };

    const newChat = async () => {
        await saveChatHistory();
        setLoading(false);
        setShowResult(false);
        setChatId(uuidv4());
        setChatHistory([]);
        console.log('Started new chat session');
    };

    const onSent = async (prompt) => {
        setResultData("");
        setLoading(true);
        setShowResult(true);
        let response;
        let currentPrompt = input;

        if (prompt !== undefined) {
            console.log('Sending custom prompt:', prompt);
            response = await runChat(prompt);
            setRecentPrompt(prompt);
            currentPrompt = prompt;
        } else {
            console.log('Sending input prompt:', input);
            setPrevPrompts(prev => [...prev, input]);
            setRecentPrompt(input);
            response = await runChat(input);
        }

        let responseArray = response.split("**");
        let newResponse = "";
        for (let i = 0; i < responseArray.length; i++) {
            if (i === 0 || i % 2 !== 1) {
                newResponse += responseArray[i];
            } else {
                newResponse += "<b>" + responseArray[i] + "</b>";
            }
        }
        let newResponse2 = newResponse.split("*").join("</br>");
        let newResponseArray = newResponse2.split(" ");
        for (let i = 0; i < newResponseArray.length; i++) {
            const nextWord = newResponseArray[i];
            delayPara(i, nextWord + " ");
        }

        setChatHistory(prev => [...prev, { id: uuidv4(), user: currentPrompt, response }]);
        setLoading(false);
        setInput("");
        console.log('Prompt sent and response received:', response);

        resetInactivityTimer();
    };

    const contextValue = {
        prevPrompts,
        setPrevPrompts,
        onSent,
        setRecentPrompt,
        recentPrompt,
        showResult,
        loading,
        resultData,
        input,
        setInput,
        newChat,
        setSidebarWidth
    };

    return (
        <Context.Provider value={contextValue}>
            {props.children}
        </Context.Provider>
    );
};

export default ContextProvider;