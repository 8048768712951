import { useState, useEffect } from 'react';

const useTypingEffect = (text, delay = 100) => { // Ajuste o delay conforme necessário
  const [typedText, setTypedText] = useState('');

  useEffect(() => {
    if (!text) {
      setTypedText(''); // Garante que o texto seja uma string vazia
      return;
    }

    const words = text.split(' ');
    let currentIndex = 0;
    setTypedText(''); // Reseta o estado ao iniciar a animação

    const intervalId = setInterval(() => {
      setTypedText(prev => {
        if (currentIndex < words.length) {
          return prev + (prev ? ' ' : '') + words[currentIndex];
        }
        clearInterval(intervalId);
        return prev;
      });
      currentIndex += 1;
    }, delay);

    return () => clearInterval(intervalId);
  }, [text, delay]);

  return typedText;
};

export default useTypingEffect;
