import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const ADDITIONAL_FOLDER = 'loja-produtos/adicionais/';

const AdditionalManager = () => {
  const [adicionais, setAdicionais] = useState([]);
  const [nome, setNome] = useState('');
  const [preco, setPreco] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const fetchAdicionais = async () => {
      try {
        const listParams = {
          Bucket: S3_BUCKET,
          Prefix: ADDITIONAL_FOLDER,
        };
        const listedObjects = await s3.listObjectsV2(listParams).promise();
        const additionalPromises = listedObjects.Contents.filter(item => item.Key.endsWith('.json')).map(async (item) => {
          const params = {
            Bucket: S3_BUCKET,
            Key: item.Key,
          };
          const data = await s3.getObject(params).promise();
          try {
            return JSON.parse(data.Body.toString('utf-8'));
          } catch (error) {
            console.error('Erro ao parsear JSON:', error, 'Arquivo:', item.Key);
            return null;
          }
        });

        const additionalData = await Promise.all(additionalPromises);
        setAdicionais(additionalData.filter(add => add !== null));
      } catch (error) {
        console.error('Erro ao buscar adicionais:', error);
      }
    };

    fetchAdicionais();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const sanitizedFileName = sanitizeFileName(nome);
      const jsonFileName = `${ADDITIONAL_FOLDER}${editMode ? editId : String(adicionais.length + 1).padStart(3, '0')}-${sanitizedFileName}.json`;

      const additionalData = {
        id: editMode ? editId : String(adicionais.length + 1).padStart(3, '0'),
        nome,
        preco: parseFloat(preco.replace('R$', '').replace(',', '.').trim()),
      };

      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: jsonFileName,
        Body: JSON.stringify(additionalData),
        ContentType: 'application/json',
        ACL: 'public-read'
      };

      await s3.upload(uploadParams).promise();
      alert('Adicional salvo com sucesso!');

      setAdicionais(prevAdicionais => {
        if (editMode) {
          return prevAdicionais.map(add => (add.id === editId ? additionalData : add));
        } else {
          return [...prevAdicionais, additionalData];
        }
      });

      setNome('');
      setPreco('');
      setEditMode(false);
      setEditId(null);
    } catch (error) {
      console.error('Erro ao salvar adicional:', error);
      alert('Erro ao salvar adicional. Verifique as configurações e tente novamente.');
    }
  };

  const handleDelete = async (id) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const additional = adicionais.find(add => add.id === id);
      if (!additional) {
        console.error('Adicional não encontrado:', id);
        alert('Adicional não encontrado.');
        return;
      }

      const sanitizedFileName = sanitizeFileName(additional.nome);
      const jsonFileName = `${ADDITIONAL_FOLDER}${id}-${sanitizedFileName}.json`;

      const deleteParams = {
        Bucket: S3_BUCKET,
        Key: jsonFileName,
      };

      await s3.deleteObject(deleteParams).promise();

      setAdicionais(adicionais.filter(add => add.id !== id));
      alert('Adicional excluído com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir adicional:', error);
      alert('Erro ao excluir adicional. Verifique as configurações e tente novamente.');
    }
  };

  const handleEdit = (additional) => {
    setNome(additional.nome);
    setPreco(additional.preco.toFixed(2).replace('.', ','));
    setEditMode(true);
    setEditId(additional.id);
  };

  const sanitizeFileName = (str) => {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  return (
    <div>
      <h1>Gerenciamento de Adicionais</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nome:</label>
          <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} required />
        </div>
        <div>
          <label>Preço:</label>
          <input type="text" value={preco} onChange={(e) => setPreco(e.target.value)} required placeholder="R$ 0,00" />
        </div>
        <button type="submit">{editMode ? 'Salvar Edição' : 'Adicionar Adicional'}</button>
      </form>
      <ul>
        {adicionais.map((additional, index) => (
          <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <span style={{ flexGrow: 1 }}>{additional.nome} - R$ {additional.preco.toFixed(2).replace('.', ',')}</span>
            <button onClick={() => handleEdit(additional)} style={{ marginRight: '10px' }}>Editar</button>
            <button onClick={() => handleDelete(additional.id)}>Excluir</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdditionalManager;
