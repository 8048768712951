import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import './PostsList.css';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const POST_FOLDER = 'loja-produtos/posts/';

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
      });

      try {
        const params = {
          Bucket: S3_BUCKET,
          Prefix: POST_FOLDER,
        };

        const data = await s3.listObjectsV2(params).promise();
        const postFiles = data.Contents.filter(file => file.Key.endsWith('.json'));

        const fetchedPosts = await Promise.all(
          postFiles.map(async (file) => {
            const fileData = await s3.getObject({ Bucket: S3_BUCKET, Key: file.Key }).promise();
            const post = JSON.parse(fileData.Body.toString('utf-8'));

            return { ...post, jsonFileKey: file.Key };
          })
        );

        setPosts(fetchedPosts);
      } catch (error) {
        console.error('Erro ao buscar os posts:', error);
      }
    };

    fetchPosts();
  }, []);

  const handleEditClick = (post) => {
    navigate(`/post-edit/${post.id}`, { state: { post } });
  };

  const handleDeleteClick = async (post) => {
    const confirmDelete = window.confirm(`Tem certeza que deseja excluir o post "${post.titulo}"?`);
    if (!confirmDelete) return;

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const deleteJsonParams = {
        Bucket: S3_BUCKET,
        Key: post.jsonFileKey,
      };
      await s3.deleteObject(deleteJsonParams).promise();

      if (post.imagem) {
        const imageKey = post.imagem.split('.com/')[1];
        const deleteImageParams = {
          Bucket: S3_BUCKET,
          Key: imageKey,
        };
        await s3.deleteObject(deleteImageParams).promise();
      }

      setPosts(posts.filter((p) => p.id !== post.id));
      alert('Post excluído com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir o post:', error);
      alert('Erro ao excluir o post. Tente novamente.');
    }
  };

  const handleImageClick = (post) => {
    navigate(`/post/${post.id}`, { state: { post } });
  };

  const handleCreatePostClick = () => {
    navigate('/criar-post');
  };

  return (
    <div className="posts-list-container">
      <div className="header">
        <h2>Lista de Posts</h2>
        <button className="create-post-button" onClick={handleCreatePostClick}>
          <FaPlus style={{ marginRight: '5px' }} />
          Criar Novo Post
        </button>
      </div>
      <div className="posts-list">
        {posts.map((post) => (
          <div key={post.jsonFileKey} className="post-item">
            <img
              src={post.imagem}
              alt={post.titulo}
              className="post-image"
              onClick={() => handleImageClick(post)}
              style={{ cursor: 'pointer' }}
            />
            <div className="post-details">
              <div className="post-header">
                <h3>{post.titulo}</h3>
                <div className="action-buttons">
                  <FaEdit
                    className="edit-icon"
                    onClick={() => handleEditClick(post)}
                    style={{ cursor: 'pointer', marginLeft: '10px' }}
                  />
                  <FaTrash
                    className="delete-icon"
                    onClick={() => handleDeleteClick(post)}
                    style={{ cursor: 'pointer', marginLeft: '10px', color: 'red' }}
                  />
                </div>
              </div>
              <p>{post.breveDescricao}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Posts;
