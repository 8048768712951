import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getOrderById } from './ordersUtils';

const PedidoView = () => {
  const location = useLocation();
  const { id } = location.state || {};
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!id) {
      setError('ID do pedido não fornecido');
      setLoading(false);
      return;
    }

    const fetchOrder = async () => {
      try {
        const orderData = await getOrderById(id);
        setOrder(orderData);
      } catch (err) {
        setError('Erro ao carregar os detalhes do pedido');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [id]);

  if (loading) {
    return <div>Carregando detalhes do pedido...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!order) {
    return <div>Pedido não encontrado</div>;
  }

  return (
    <div>
      <h1>Detalhes do Pedido</h1>
      <p><strong>ID do Pedido:</strong> {order.id}</p>
      <p><strong>Cliente:</strong> {order.cliente.nome}</p>
      <p><strong>Endereço:</strong> {order.cliente.endereco}, {order.cliente.numero}</p>
      <p><strong>Email:</strong> {order.cliente.email}</p>
      <p><strong>Telefone:</strong> {order.cliente.telefone}</p>
      <p><strong>Data do Pedido:</strong> {new Date(order.date).toLocaleString()}</p>
      <p><strong>Total:</strong> R$ {order.totalFinal}</p>
      <p><strong>Forma de Pagamento:</strong> {order.formaPagamento}</p>
      <p><strong>Entrega:</strong> {order.entrega} - R$ {order.entregaValor}</p>
      <p><strong>Desconto:</strong> R$ {order.desconto}</p>
      <h2>Itens do Pedido</h2>
      <ul>
        {order.items.map((item, index) => (
          <li key={index}>
            <strong>{item.nome}</strong> - Quantidade: {item.quantidade} - Preço: R$ {item.preco}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PedidoView;
