import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>
      <h1>Bem-vindo</h1>
      <nav>
        <ul>
          <li>
            <Link to="/produtos">Produtos</Link>
          </li>
          <li>
            <Link to="/cupons">Cupons</Link>
          </li>
          <li>
            <Link to="/adicionais">Adicionais</Link>
          </li>
          <li>
            <Link to="/categorias">Categorias</Link>
          </li>
          <li>
            <Link to="/clientes">Clientes</Link>
          </li>
          <li>
            <Link to="/posts">Posts Marketing</Link>
          </li>
          <li>
            <Link to="/impostos">Impostos</Link>
          </li>
          <li>
            <Link to="/abertura">Abertura</Link>
          </li>
          <li>
            <Link to="/entregas-admin">Entregas</Link>
          </li>

        </ul>
      </nav>
    </div>
  );
};

export default Home;
