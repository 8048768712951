import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const COUPON_FOLDER = 'loja-produtos/cupons/';

const Cupons = () => {
  const [cupons, setCupons] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const fetchCupons = async () => {
      try {
        const listParams = {
          Bucket: S3_BUCKET,
          Prefix: COUPON_FOLDER,
        };
        const listedObjects = await s3.listObjectsV2(listParams).promise();
        const cuponPromises = listedObjects.Contents.filter(item => item.Key.endsWith('.json')).map(async (item) => {
          const params = {
            Bucket: S3_BUCKET,
            Key: item.Key,
          };
          const data = await s3.getObject(params).promise();
          const cupom = JSON.parse(data.Body.toString('utf-8'));
          return { ...cupom, s3Key: item.Key };
        });

        const cuponData = await Promise.all(cuponPromises);
        setCupons(cuponData);
      } catch (error) {
        console.error('Erro ao buscar cupons:', error);
      }
    };

    fetchCupons();
  }, []);

  const handleEdit = (id) => {
    navigate(`/editar-cupom/${id}`);
  };

  const handleDelete = async (s3Key, codigo) => {
    const confirmed = window.confirm(`Tem certeza que deseja excluir o cupom "${codigo}"?`);
    if (confirmed) {
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
      });

      try {
        const deleteParams = {
          Bucket: S3_BUCKET,
          Key: s3Key,
        };

        await s3.deleteObject(deleteParams).promise();
        alert(`Cupom "${codigo}" excluído com sucesso!`);
        setCupons(cupons.filter(cupom => cupom.s3Key !== s3Key));
      } catch (error) {
        console.error('Erro ao excluir o cupom:', error);
        alert('Erro ao excluir o cupom. Por favor, tente novamente.');
      }
    }
  };

  const handleCreateNewCoupon = () => {
    navigate('/adicionar-cupom');
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Lista de Cupons</h1>
      <button
        onClick={handleCreateNewCoupon}
        style={{
          marginBottom: '20px',
          padding: '10px 20px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}
      >
        Criar Novo Cupom
      </button>
      <div>
        {cupons.map((cupom, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>
            <img
              src={cupom.banner}
              alt={`Banner do ${cupom.codigo}`}
              style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '20px' }}
            />
            <div style={{ flexGrow: 1 }}>
              <h2 style={{ margin: '0 0 5px 0' }}>{cupom.codigo}</h2>
              <p style={{ margin: '0 0 5px 0', color: '#555' }}>{cupom.descricao}</p>
              <p style={{ margin: '0 0 5px 0', fontSize: '14px', color: '#777' }}>Validade: {new Date(cupom.dataValidade).toLocaleDateString()}</p>
              <p style={{ margin: '0', fontSize: '14px', fontWeight: 'bold' }}>
                Desconto: {cupom.tipoDesconto === 'dinheiro' ? `R$ ${cupom.valorDesconto.toFixed(2)}` : `${cupom.valorDesconto.toFixed(2)}%`}
              </p>
            </div>
            <button onClick={() => handleEdit(cupom.id)} style={{ marginLeft: '10px', cursor: 'pointer' }}>✏️ Editar</button>
            <button onClick={() => handleDelete(cupom.s3Key, cupom.codigo)} style={{ marginLeft: '10px', cursor: 'pointer', color: 'red' }}>🗑️ Excluir</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cupons;
