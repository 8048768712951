import {GoogleGenerativeAI, HarmCategory, HarmBlockThreshold } from "@google/generative-ai";
import axios from 'axios';

const MODEL_NAME = "gemini-1.5-pro";
const API_KEY = "AIzaSyCbwh0RKMnf6IFqtAr4wBZUgFBqKAC9y5s";
const ENDPOINT_URL = "https://ecommerce-app-strapi-expo.s3.sa-east-1.amazonaws.com/assistente/memoria.json"; // Substitua pela URL real do seu endpoint

const genAI = new GoogleGenerativeAI(API_KEY);

async function fetchAndPrepareContext() {
  try {
    const response = await axios.get(ENDPOINT_URL);
    const qaData = response.data;

    let context = "Você é o Jhon, assistente da Appclouds, empresa que desenvolve aplicativos:\n\n";

    for (const qa of qaData.qa_pairs) {
      context += `Pergunta: ${qa.question}\nResposta: ${qa.answer}\n\n`;
    }

    return context;
  } catch (error) {
    console.error("Erro ao buscar e preparar o contexto:", error);
    throw error;
  }
}

async function runChat(userPrompt) {
  try {
    const context = await fetchAndPrepareContext();

    const model = genAI.getGenerativeModel({ model: MODEL_NAME });

    const generationConfig = {
      temperature: 0.7,
      topK: 1,
      topP: 1,
      maxOutputTokens: 348,
    };

    const safetySettings = [
      {
        category: HarmCategory.HARM_CATEGORY_HARASSMENT,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
    ];

    const chat = model.startChat({
      generationConfig,
      safetySettings,
      history: [],
    });

    const result = await chat.sendMessage(`${context}\n\nAgora, responda à seguinte pergunta com base nas informações fornecidas acima:\n${userPrompt}`);

    const response = result.response;
    console.log(response.text());
    return response.text();
  } catch (error) {
    console.error("Erro durante a execução do chat:", error);
    throw error;
  }
}

export default runChat;