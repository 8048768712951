import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { listOrdersFromS3 } from './ordersUtils';

const Pedidos = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersData = await listOrdersFromS3();
        const sortedOrders = ordersData.sort((a, b) => new Date(b.date) - new Date(a.date));
        setOrders(sortedOrders);
      } catch (err) {
        setError('Erro ao carregar pedidos');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleOrderClick = (id) => {
    navigate('/pedido', { state: { id } });
  };

  if (loading) {
    return <div>Carregando pedidos...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h1>Pedidos</h1>
      <ul>
        {orders.map((order) => {
          const orderDate = new Date(order.date);
          const customerName = order.cliente?.nome || 'Nome não disponível';

          return (
            <li
              key={order.id}
              style={{ marginBottom: '10px', cursor: 'pointer' }}
              onClick={() => handleOrderClick(order.id)}
            >
              <strong>Cliente:</strong> {customerName} <br />
              <strong>Data e Hora:</strong> {isNaN(orderDate) ? 'Data inválida' : orderDate.toLocaleString()}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Pedidos;
