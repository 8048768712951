import React from 'react';

const CategoryList = ({ categories, onEdit, onDelete }) => {
  return (
    <div>
      <h2>Categorias</h2>
      <ul>
        {categories.map((category, index) => (
          <li key={index}>
            <span>{category.nome}</span>
            <button onClick={() => onEdit(category)}>Editar</button>
            <button onClick={() => onDelete(category.id)}>Excluir</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;
