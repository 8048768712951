import React from 'react';
import { useLocation } from 'react-router-dom';

const ProductView = () => {
  const location = useLocation();
  const product = location.state?.product;

  if (!product) {
    return <div>Produto não encontrado.</div>;
  }

  return (
    <div>
      <h1>{product.nome}</h1>
      <div>
        {product.imagens.map((imagem, index) => (
          <img
            key={index}
            src={imagem}
            alt={`${product.nome} ${index}`}
            style={{ width: '200px', height: '200px', objectFit: 'cover', marginRight: '10px' }}
          />
        ))}
      </div>
      <h2>Preço: R$ {product.precoPromocional ? product.precoPromocional.toFixed(2) : product.preco.toFixed(2)}</h2>
      {product.precoPromocional && (
        <h3 style={{ textDecoration: 'line-through' }}>Preço Original: R$ {product.preco.toFixed(2)}</h3>
      )}
      <h3>Breve Descrição:</h3>
      <p>{product.breveDescricao}</p>
      <h3>Descrição:</h3>
      <p>{product.descricao}</p>
      {product.video && (
        <div>
          <h3>Vídeo:</h3>
          <a href={product.video} target="_blank" rel="noopener noreferrer">
            {product.video}
          </a>
        </div>
      )}
      {product.imgBanner && (
        <div>
          <h3>Banner:</h3>
          <img src={product.imgBanner} alt="Banner" style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }} />
          <p>{product.textoBanner}</p>
        </div>
      )}
      {product.adicionais && product.adicionais.length > 0 && (
        <div>
          <h3>Adicionais:</h3>
          <ul>
            {product.adicionais.map((adicional, index) => (
              <li key={index}>
                {adicional.nome} - R$ {adicional.preco.toFixed(2).replace('.', ',')}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProductView;
