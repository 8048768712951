import React from 'react';
import AppRoutes from './routes/Routes';
import ContextProvider from './pages/Abertura/Context';

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <AppRoutes />
      </ContextProvider>
    </div>
  );
}

export default App;
